import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Posts } from "@components";
import Layout from "@layouts";
import { useSearch } from "@hooks";
import { filteredPosts, sortPostsByDate } from "@lib/utils";

const IndexPage = () => {
  const {
    allStrapiPost: { edges: posts },
  } = useStaticQuery(query);
  const { searchQuery } = useSearch();

  const sortedPosts = useMemo(() => sortPostsByDate(posts), [posts]);
  const filteredData = useMemo(() => filteredPosts(sortedPosts, searchQuery), [sortedPosts, searchQuery]);

  return (
    <Layout
      banner={{
        description: "Stories from the team that makes you accomplish more with your money.",
        title: "OPay Blog",
      }}
      seoTitle="OPay Blog"
    >
      <Posts data={filteredData} totalCount={filteredData.length} />
    </Layout>
  );
};

export default IndexPage;

const query = graphql`
  query {
    allStrapiPost {
      totalCount
      edges {
        node {
          id
          strapiId
          title
          excerpt
          published_at
          slug
          image {
            childImageSharp {
              fluid(maxWidth: 500, quality: 100) {
                ...GatsbyImageSharpFluid
                ...GatsbyImageSharpFluidLimitPresentationSize
              }
            }
          }
          category {
            name
            slug
          }
          author {
            name
          }
        }
      }
    }
  }
`;
