import compareAsc from "date-fns/compareAsc";

const formatTagsArray = (arr) => arr.map((x) => x.name.toLowerCase());

export const filteredPosts = (data, query) => {
  const queryLowered = query.toLowerCase();

  if (!data.length || !query) {
    return data;
  }

  return data.filter(
    ({ node }) =>
      node?.title?.toLowerCase().includes(queryLowered) ||
      node?.excerpt?.toLowerCase().includes(queryLowered) ||
      node?.author?.name?.toLowerCase().includes(queryLowered) ||
      formatTagsArray(node.tags).includes(queryLowered),
  );
};

export const sortPostsByDate = (data) =>
  data.sort((a, b) => compareAsc(new Date(b.node.published_at), new Date(a.node.published_at)));
